import React from "react";

const FunctionsCard = (props) => {
  const { focused, setFocused } = props;

  return (
    <div class={`flex bg-gradient-to-tl from-cortexx-600 ${props.color}`}>
      <div class="ml-2 flex flex-col bg-cortexx-50 pl-8">
        <h1 class="text-4xl font-extrabold tracking-tight text-cortexx-900">
          <span class="block xl:inline">{props.title}</span>
        </h1>
        {props.children}
      </div>
    </div>
  );
};

export default FunctionsCard;
