import React from "react";
import useScreenSize from "../utils/useScreenSize";

const SolutionCard = (props) => {
  const { id, name, description, icon, sectionLink } = props.card;
  const { focused, setFocused } = props;
  const screenSize = useScreenSize();

  return (
    <a
      class={`flex transition-all duration-1000 sm:flex-row sm:text-xs ${
        focused === "none"
          ? "opacity-100"
          : focused === id
          ? "opacity-100"
          : "opacity-50"
      }`}
      onMouseOver={() => screenSize.width > 620 && setFocused(id)}
      onMouseOut={() => screenSize.width > 620 && setFocused("none")}
      onClick={(e) => {
        if (screenSize.width < 620) setFocused(id);
      }}
      href={sectionLink}
    >
      <div
        class={`mb-2 flex h-20 w-20 items-center justify-center rounded-xl bg-cortexx-1000 bg-opacity-50 sm:h-24 sm:w-24`}
      >
        {icon}
      </div>
      <div class={`ml-4 flex w-20 items-center justify-start`}>
        <p class="text-xxs font-bold text-cortexx-50 sm:text-sm ">{name}</p>
      </div>
      <div class={"ml-4 flex w-64 items-center justify-center"}>
        <p
          class={`font-base text-xxs text-cortexx-50 transition-all duration-1000 sm:text-xs ${
            focused === id ? "opacity-100" : "opacity-0"
          }`}
        >
          {description}
        </p>
      </div>
    </a>
  );
};

export default SolutionCard;
