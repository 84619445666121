import { parse } from "qs";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import LanguageDropdown from "../components/LanguageDropdown";
import Technology from "./placeholders/Technology";
import Platform from "./placeholders/Platform";
import News from "./placeholders/News";
import UseCases from "./placeholders/UseCases";
import PatientBenefit from "./placeholders/PatientBenefit";

const HomePage = () => {
  const { t, i18n } = useTranslation();
  const { location } = useParams();

  React.useState(() => {
    const params = parse(location?.search.replace("?", ""));
    i18n.changeLanguage(params.lang || "fr");
  }, location);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const toggleBurgerMenu = () => {
    var element = document.getElementById("bm-content");
    element.classList.toggle("hidden");
  };

  return (
    <div>
      <body className="relative w-full antialiased">
        <div className="relative overflow-hidden bg-white">
          <div className="relative overflow-hidden bg-cortexx-900">
            <div className="container mx-auto">
              <div className="relative z-10 bg-cortexx-900 pb-8 sm:pb-16 md:pb-20 lg:w-full lg:max-w-4xl lg:pb-28 xl:pb-32">
                <svg
                  className="absolute inset-y-0 right-0 hidden h-full w-48 translate-x-1/2 text-cortexx-900 transform lg:block"
                  fill="currentColor"
                  viewBox="0 0 100 100"
                  preserveAspectRatio="none"
                  aria-hidden="true"
                >
                  <polygon points="50,0 100,0 50,100 0,100" />
                </svg>

                <div className="relative px-4 pt-6 sm:px-6 lg:px-8">
                  <nav
                    className="relative flex h-auto items-center justify-between sm:h-auto"
                    aria-label="Global"
                  >
                    <div className="flex flex-shrink-0 flex-grow items-center lg:flex-grow-0">
                      <div className="flex w-full items-center justify-between md:w-auto">
                        <a href="/">
                          <span className="sr-only">Cortexxmi</span>
                          <img
                            className="h-24 w-auto sm:h-24 "
                            src="./img/Cortexx-logo-v2.png"
                            alt=""
                          />
                        </a>
                        <div className="-mr-2 flex items-center justify-center md:hidden">
                          <button
                            id="bm-open"
                            onClick={toggleBurgerMenu}
                            type="button"
                            className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cortexx-500"
                            aria-haspopup="true"
                          >
                            <span className="sr-only">Open main menu</span>
                            <svg
                              className="h-6 w-6"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M4 6h16M4 12h16M4 18h16"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="hidden md:ml-10 md:block md:space-x-8 md:pb-1 md:pr-4">
                      <Link
                        to="#technology"
                        className="text-sm font-bold text-white hover:text-cortexx-400"
                      >
                        {t("technology.title_short").toUpperCase()}
                      </Link>
                      <Link
                        to="#platform"
                        className="text-sm font-bold text-white hover:text-cortexx-400"
                      >
                        {t("platform.title_short").toUpperCase()}
                      </Link>
                      <Link
                        to="#patient_benefits"
                        className="text-sm font-bold text-white hover:text-cortexx-400"
                      >
                        {t("patient_benefits.title_short").toUpperCase()}
                      </Link>
                      <Link
                        to="#news"
                        className="text-sm font-bold text-white hover:text-cortexx-400"
                      >
                        {t("news.title_short").toUpperCase()}
                      </Link>
                      <Link
                        to="#contact"
                        className="text-sm font-bold text-white hover:text-cortexx-400"
                      >
                        {t("footer.contact.title_short").toUpperCase()}
                      </Link>
                    </div>
                    <div className="">
                      <LanguageDropdown
                        setLanguage={changeLanguage}
                        value={i18n.language}
                      />
                    </div>
                  </nav>
                </div>

                {/* <!--
                            Mobile menu, show/hide based on menu open state.
    
                            Entering: "duration-150 ease-out"
                            From: "opacity-0 scale-95"
                            To: "opacity-100 scale-100"
                            Leaving: "duration-100 ease-in"
                            From: "opacity-100 scale-100"
                            To: "opacity-0 scale-95"
                        --> */}
                <div
                  id="bm-content"
                  className="absolute inset-x-0 top-0 hidden origin-top-right p-2 transition transform md:hidden"
                >
                  <div className="overflow-hidden rounded-lg bg-white shadow-md ring-1 ring-black ring-opacity-5">
                    <div className="flex items-center justify-between px-5 pt-4">
                      <div>
                        <img
                          className="h-8 w-auto"
                          src="./img/CorteXx-Logo-mobile.png"
                          alt=""
                        />
                      </div>
                      <div className="-mr-2">
                        <button
                          id="bm-close"
                          onClick={toggleBurgerMenu}
                          type="button"
                          className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cortexx-500"
                        >
                          <span className="sr-only">Close main menu</span>
                          <svg
                            className="h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="main-menu"
                    >
                      <div className="space-y-1 px-2 pb-3 pt-2" role="none">
                        <Link
                          to="#technology"
                          className="block rounded-md px-3 py-2 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                          role="menuitem"
                        >
                          {t("technology.title_short")}
                        </Link>
                        <Link
                          to="#research"
                          className="block rounded-md px-3 py-2 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                          role="menuitem"
                        >
                          {t("news.title_short")}
                        </Link>
                        <Link
                          to="#partners"
                          className="block rounded-md px-3 py-2 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                          role="menuitem"
                        >
                          {t("research.title")}
                        </Link>
                        <Link
                          to="#team"
                          className="block rounded-md px-3 py-2 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                          role="menuitem"
                        >
                          {t("partners.title_short")}
                        </Link>
                        <Link
                          to="#contact"
                          className="block rounded-md px-3 py-2 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                          role="menuitem"
                        >
                          {t("team.title")}
                        </Link>
                        <Link
                          to="#contact"
                          className="block rounded-md px-3 py-2 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900"
                          role="menuitem"
                        >
                          {t("footer.contact.title_short")}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <main className="mx-auto mt-10 max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
                  <div className="sm:text-center lg:text-left">
                    <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl md:text-6xl">
                      <span className="block xl:inline">
                        {t("title.line_1")}
                      </span>
                      <span className="block xl:inline"> </span>
                      <span className="block text-cortexx-400 xl:inline">
                        {t("title.line_2")}
                      </span>
                    </h1>
                    <p className="mt-3 text-base text-white sm:mx-auto sm:mt-5 sm:max-w-xl sm:text-lg md:mt-5 md:text-xl lg:mx-0">
                      {t("title.subtitle")}
                    </p>
                    <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                      <div className="rounded-md shadow">
                        <a
                          href="/#research"
                          className="flex w-full items-center justify-center rounded-md border border-transparent bg-white px-8 py-3 text-base font-bold text-gray-900 hover:opacity-80 md:px-10 md:py-4 md:text-xs"
                        >
                          {t("title.learn_more").toUpperCase()}
                        </a>
                      </div>
                      <div className="mt-3 sm:ml-3 sm:mt-0">
                        <a
                          href="/#contact"
                          className="flex w-full items-center justify-center rounded-md border border-white px-8 py-3 text-base font-bold text-white hover:opacity-80 md:px-10 md:py-4 md:text-xs"
                        >
                          {t("title.contact_us").toUpperCase()}
                        </a>
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>
            <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
              <img
                className="h-56 w-full object-cover sm:h-72 md:h-96 lg:h-full lg:w-full"
                src="./img/homepage-cover.jpg"
                alt=""
              />
            </div>
          </div>
          <Technology />
          <Platform />
          <PatientBenefit />
          <UseCases />
          <News />
        </div>

        <section id="contact" className="body-font relative text-gray-700">
          <div className="container mx-auto px-5 py-24">
            <div className="flex w-full flex-col text-center">
              <h1 className="title-font mb-4 text-2xl font-medium text-gray-900 sm:text-3xl">
                {t("footer.contact.title")}
              </h1>
              <p className="mx-auto text-base leading-relaxed lg:w-2/3">
                {t("footer.contact.message")}
              </p>
            </div>

            <div className="mx-auto md:w-2/3 lg:w-1/2">
              <div className="flex flex-wrap">
                <div className="mt-8 w-full p-2 text-center">
                  <a className="text-cortexx-500">contact@cortexxmi.com</a>
                  <p className="my-5 leading-normal">
                    © 2024 Cortexx Medical Intelligence SAS
                    <br />
                    11 rue de Quatrefages <br />
                    75005 Paris
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <footer className="body-font bg-cortexx-900 text-white">
          <div className="md:flex-no-wrap container mx-auto flex flex-col flex-wrap px-5 py-12 md:flex-row md:items-center">
            <div className="mx-auto w-64 flex-shrink-0 text-center md:mx-0 md:text-left">
              <a className="title-font flex items-center justify-center font-medium md:justify-start">
                <span className="text-xl">cortexxmi.com</span>
              </a>
            </div>
            <ul className="flex flex-grow flex-wrap text-center md:pl-20 md:text-left">
              <li className="w-full px-4 md:w-1/2 lg:w-1/2">
                <span className="title-font text-sm font-medium tracking-widest">
                  <a
                    href="sumo/user-privacy"
                    className="uppercase text-cortexx-100 hover:underline"
                  >
                    {t("footer.privacy")}
                  </a>
                </span>
              </li>
            </ul>

            <span className="mt-2 inline-flex justify-center sm:ml-auto sm:mt-0 sm:justify-start">
              <a
                className="ml-3"
                href="https://www.linkedin.com/company/cortexxmi/"
              >
                <svg
                  fill="currentColor"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="0"
                  className="h-5 w-5"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="none"
                    d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
                  ></path>
                  <circle cx="4" cy="4" r="2" stroke="none"></circle>
                </svg>
              </a>
            </span>
          </div>
          <div className="hidden">
            Icons made by{" "}
            <a href="https://www.freepik.com" title="Freepik">
              Freepik
            </a>{" "}
            from{" "}
            <a href="https://www.flaticon.com/" title="Flaticon">
              www.flaticon.com
            </a>
          </div>
        </footer>

        <script src="{{ asset('css/app.js') }}" />
      </body>
    </div>
  );
};

export default HomePage;
