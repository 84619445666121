import React from "react";
import { useTranslation } from "react-i18next";

const Technology = () => {
  const { t } = useTranslation();

  return (
    <section id="technology" className="body-font bg-white text-gray-700">
      <div className="container mx-auto flex flex-col items-center justify-center px-5 pt-20">
        <div className="mb-10 flex  w-full flex-col">
          <div className="mb-10">
            <h1 class="font-extrabold tracking-tight text-cortexx-900 sm:text-4xl md:text-5xl">
              {t("technology.title")}
            </h1>
          </div>
          <div className="flex-row  lg:flex">
            <div className="my-4 mr-5 h-auto w-1/3 leading-relaxed ">
              <h2 className="title-font mb-8 text-xl font-medium text-gray-600 sm:text-2xl">
                {t("technology.ai.title")}
              </h2>
              <p className="mb-8 text-gray-500">{t("technology.ai.line_1")}</p>
              <p className="text-gray-500">{t("technology.ai.line_2")}</p>
            </div>
            <div className="my-4 mr-5 h-auto w-1/3 leading-relaxed ">
              <h2 className="title-font mb-8 text-xl font-medium text-gray-600 sm:text-2xl">
                {t("technology.rpm.title")}
              </h2>
              <p className="mb-8 text-gray-500">{t("technology.rpm.line_1")}</p>
              <p className="text-gray-500">{t("technology.rpm.line_2")}</p>
            </div>
            <div className="my-4 mr-5 h-auto w-1/3 leading-relaxed ">
              <h2 className="title-font mb-8 text-xl font-medium text-gray-600 sm:text-2xl">
                {t("technology.dtx.title")}
              </h2>
              <p className="mb-8 text-gray-500">{t("technology.dtx.line_1")}</p>
              <p className="text-gray-500">{t("technology.dtx.line_2")}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Technology;
