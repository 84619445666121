import React from "react";
import { useTranslation } from "react-i18next";

const UseCases = () => {
  const { t } = useTranslation();

  return (
    <section id="usecases" className="body-font bg-white text-gray-700">
      <div className="container mx-auto flex flex-col px-5 pt-20">
        <div className="mb-10 flex flex-col">
          <div className="mb-10">
            <h1 class="font-extrabold tracking-tight text-cortexx-900 sm:text-4xl md:text-5xl">
              {t("health_system_benefits.title")}
            </h1>
          </div>
          <div className="mb-8 flex-row items-stretch justify-center lg:flex">
            <div className="group mr-20 h-auto w-1/2 rounded-xl bg-cortexx-200 bg-gradient-to-tl from-cortexx-600 transition-all duration-500 hover:p-2">
              <div className="h-full rounded-xl  bg-cortexx-100 p-8 leading-relaxed transition-all duration-1000 group-hover:bg-cortexx-200 group-hover:bg-gradient-to-tl group-hover:from-cortexx-600">
                <h2 className="mb-8 text-2xl font-bold text-cortexx-700 group-hover:text-white">
                  {t("health_system_benefits.surgeon.title")}
                </h2>
                <p className="mb-8 text-gray-500 group-hover:text-white">
                  {t("health_system_benefits.surgeon.text_1")}
                </p>
                <p className="font-bold italic text-cortexx-700 group-hover:text-white">
                  {t("health_system_benefits.surgeon.highlight")}
                </p>
              </div>
            </div>
            <div className="group mr-20 h-auto w-1/2 rounded-xl bg-cortexx-400 bg-gradient-to-tl from-cortexx-600 transition-all duration-500 hover:p-2">
              <div className="h-full rounded-xl  bg-cortexx-100 p-8 leading-relaxed transition-all duration-1000 group-hover:bg-cortexx-400 group-hover:bg-gradient-to-tl group-hover:from-cortexx-600">
                <h2 className="mb-8 text-2xl font-bold text-cortexx-700 group-hover:text-white">
                  {t("health_system_benefits.research.title")}
                </h2>
                <p className="mb-4 text-gray-600 group-hover:text-white">
                  {t("health_system_benefits.research.text_1")}
                </p>
                <p className="mb-8 text-gray-600 group-hover:text-white">
                  {t("health_system_benefits.research.text_2")}
                </p>
                <p className="font-bold italic text-cortexx-700 group-hover:text-white">
                  {t("health_system_benefits.research.highlight")}
                </p>
              </div>
            </div>
          </div>
          <div className="mb-8 flex-row items-stretch justify-center lg:flex">
            <div className="group mr-20 h-auto w-1/2 rounded-xl bg-cortexx-300 bg-gradient-to-tl from-cortexx-600 transition-all duration-500 hover:p-2">
              <div className="h-full rounded-xl  bg-cortexx-100 p-8 leading-relaxed transition-all duration-1000 group-hover:bg-cortexx-300 group-hover:bg-gradient-to-tl group-hover:from-cortexx-600">
                <h2 className="mb-8 text-2xl font-bold text-cortexx-700 group-hover:text-white">
                  {t("health_system_benefits.medtech.title")}
                </h2>
                <p className="mb-8 text-gray-500 group-hover:text-white">
                  {t("health_system_benefits.medtech.text_1")}
                </p>
                <p className="mb-8 text-gray-500 group-hover:text-white">
                  {t("health_system_benefits.medtech.text_2")}
                </p>
                <p className="align-bottom font-bold italic text-cortexx-700 group-hover:text-white">
                  {t("health_system_benefits.medtech.highlight")}
                </p>
              </div>
            </div>
            <div className="group mr-20 h-auto w-1/2 rounded-xl bg-cortexx-800 bg-gradient-to-tl from-cortexx-600 transition-all duration-500 hover:p-2">
              <div className="h-full rounded-xl  bg-cortexx-100 p-8 leading-relaxed transition-all duration-1000 group-hover:bg-cortexx-800 group-hover:bg-gradient-to-tl group-hover:from-cortexx-600">
                <h2 className="mb-8 text-2xl font-bold text-cortexx-700 group-hover:text-white">
                  {t("health_system_benefits.insurance.title")}
                </h2>
                <p className="mb-8 text-gray-500 group-hover:text-white">
                  {t("health_system_benefits.insurance.text_1")}
                </p>
                <p className="font-bold italic text-cortexx-700 group-hover:text-white">
                  {t("health_system_benefits.insurance.highlight")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UseCases;
